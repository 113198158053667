import React from "react";
import purpleLogo from "../Images/purples-logo.jpg";
const Header = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="logo">
          <img src={purpleLogo} className="img-responsive" alt="logo" />
        </div>
      </div>
    </div>
  );
};
export default Header;
