import { React } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Icon } from "@iconify/react";
import assignmentCheck from "@iconify-icons/zmdi/assignment-check";
import { Redirect } from "react-router-dom";
import HeaderLower from "./HeaderLower";

function TestOurTech_thankyou(props) {
  if (!props.history.location.state?.showroom) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <div className="container-fluid">
        <Header />
        <HeaderLower />
        <div className="white-box thank">
          <h1 className="text-center">Thank You! </h1>
          <Icon icon={assignmentCheck} />
          {/* <p>
              You will receive an email with your unique code from our
              fulfillment center. Once you receive your email you will be given
              instructions on redeeming your Reward Card. For further
              information please call{" "}
              <a href="tel:866-ELITE-21">866-ELITE-21</a> or email us at{" "}
              <a href="mailto:customercare@eliterewards.biz">
                customercare@eliterewards.biz
              </a>{" "}
              and one of our customer service representatives will be happy to
              assist you.
            </p> */}
          <a href="/" className="btn btn-md btn-primary">
            Go Back
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default TestOurTech_thankyou;
