import { React, useEffect, useRef, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Header from "./Header";
import Footer from "./Footer";
import HeaderLower from "./HeaderLower";
import swal from "sweetalert";

import RegisterService from "../services/register.service";

function RegisterForm(props) {
  const [fields, setFields] = useState({
    sales_name: "",
    name: "",
    lname: "",
    emailid: "",
    showroom: "",
    address: "",
    phone: "",
    question: "",
  });

  const [errors, setErrors] = useState({});

  const [value_userform, setValue_userform] = useState([]);
  const [locations, setLocations] = useState([]);

  localStorage.setItem("isRegister", false);
  const ref = useRef();
  useEffect(() => {
    async function getLocations() {
      try {
        const response = await RegisterService.getStoreLocation();

        var locations = response.data.response;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.id,
            label: value.store_name,
          });
        });
        await setLocations([...results]);
      } catch (err) {
        setLocations([]);
      }
    }
    getLocations();
  }, []);
  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const checkedValue = (e) => {
    let value = "yes";
    if (e === false || e === "") {
      value = "no";
    }
    return value;
  };

  const handleChange = (e) => {
    let { value, checked, name } = e.target;
    if (name === "showroom" && value === "true") {
      value = "";
    }
    if (name !== "question") {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    } else {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: checked,
        };
      });
    }
  };

  const saveErrors = (err) => {
    setErrors(err);
  };

  // const verifyCallback = (response) => {
  //   let errors = {};
  //   if (response) {
  //     setCaptcha({
  //       captcha: true,
  //     });
  //   } else {
  //     setErrors(errors);
  //     return false;
  //   }
  // };

  const submituserRegistrationForm = async (e) => {
    e.preventDefault();

    let data = {
      sales_name: fields?.sales_name,
      first_name: fields?.name,
      last_name: fields?.lname,
      email: fields?.emailid,
      store_id: fields?.showroom,
      address1: fields?.address,
      phone: fields?.phone,
      question: checkedValue(fields?.question),
    };
    try {
      const response = await RegisterService.regData(data);
      console.log(response);

      var valueUserform = response?.data?.status;
      setValue_userform(valueUserform);
      localStorage.removeItem("isRegister");
      swal({
        title: "Successful!",
        text: "Form Submitted!",
        type: "success",
        icon: "success",
      }).then(() => {
        props.history.push({
          pathname: "/TestOurTech_thankyou",
          state: {
            sales_name: fields?.sales_name,
            name: fields.name,
            lname: fields.lname,
            emailid: fields.emailid,
            showroom: fields.showroom,
            address: fields?.address,
            phone: fields?.phone,
            question: fields?.question,
          },
        });
      });
      if (value_userform?.includes("redirect")) {
      }
    } catch (err) {
      console.log(err);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        swal({
          icon: "error",
          title: "Oops...",
          text: err?.response?.data?.message,
        });
      }
      let errorData = {};
      errorData["sales_name"] =
        err?.response?.data &&
        err?.response?.data?.sales_name &&
        err?.response?.data?.sales_name[0];
      errorData["name"] =
        err?.response?.data &&
        err?.response?.data?.first_name &&
        err?.response?.data?.first_name[0];
      errorData["lname"] =
        err?.response?.data &&
        err?.response?.data?.last_name &&
        err?.response?.data?.last_name[0];
      errorData["emailid"] =
        err?.response?.data &&
        err?.response?.data?.email &&
        err?.response?.data?.email[0];
      errorData["showroom"] =
        err?.response?.data &&
        err?.response?.data?.store_id &&
        err?.response?.data?.store_id[0];
      errorData["address"] =
        err?.response?.data &&
        err?.response?.data?.address1 &&
        err?.response?.data?.address1[0];
      errorData["phone"] =
        err?.response?.data &&
        err?.response?.data?.phone &&
        err?.response?.data?.phone[0];
      errorData["checkederrortwo"] =
        err?.response?.data &&
        err?.response?.data?.question &&
        err?.response?.data?.question[0];
      saveErrors(errorData);
      setTimeout(() => {
        ref?.current?.focus();
      }, 200);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form">
            {/* <h1>Register to score your free Reward Card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Customer Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Customer Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                    style={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Showroom Location <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="showroom"
                    id="showroom"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value="">Select Showroom Location</option>

                    {locations.map((opt, index) => (
                      <option key={index} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                  <div className="errorMsg">
                    {errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.showroom
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sale Associate Name"
                    style={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="sales_name"
                    id="sales_name"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.sales_name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name &&
                    !errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name
                      : null}
                  </div>
                </div>
                <br />

                <li>
                  <label>
                    <input
                      type="checkbox"
                      id="question"
                      name="question"
                      className="conditions"
                      value={fields.question}
                      onChange={handleChange}
                    />
                    <span>
                      {" "}
                      Sign up to receive Email and SMS updates from Trivett’s
                      Furniture and Purple.
                    </span>
                    {/* <div className="errorMsg">{errors.checkederror}</div> */}
                  </label>
                </li>

                {/* <br />
                <br />
                <div className="overflow-hider">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={onLoadRecaptcha}
                  />
                </div>
                <div className="errorMsg">{errors.checkederrorcaptcha}</div> */}

                <br />
                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn1 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RegisterForm;
